<template>
  <vx-card
    :title="title"
    title-color="success"
  >
    <div>
      <div class="vx-row mb-12">
        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Scrap ID</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="code"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        
        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>External Code</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="externalCode"
                />
                <span class="text-danger text-sm" v-show="errors.has('ExternalCode')">{{
                errors.first("ExternalCode")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <multiselect
                v-model="selectedWarehouse"
                :options="optionWarehouse"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelWarehouse"
                @select="getWarehouseArea($event)"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('Warehouse')"
                >{{ errors.first("Warehouse") }}</span
              >
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Document Date</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <template>
                  <datepicker name="date" :disabledDates="disabledDates" :inline="false" v-model="date" placeholder="Select Date" :required="true"></datepicker>
              </template>
            </div>
          </div>
        </div>

        <!-- <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <template>
                    <datepicker :disabledDates="disabledDates" name="posting_date" :inline="false" v-model="posting_date" placeholder="Select Date"></datepicker>
                </template>
            </div>
          </div>
        </div> -->

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Scrap Type</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <multiselect
                  v-model="selectedScrapType"
                  :options="optionScrapType"
                  :max-height="100"
                  :limit="3"
                  placeholder="Type to search"
                  :searchable="true"
                  :custom-label="customLabelScrapType"
                />
              <span
                class="text-danger text-sm"
                v-show="errors.has('ScrapType')"
                >{{ errors.first("ScrapType") }}</span
              >
            </div>
          </div>
        </div>

        <!-- <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <multiselect
                class="selectExample"
                v-model="selectedSupplier"
                :options="optionSupplier"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelSupplier"
                @select="getItemsSupplier($event)"   
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('Supplier')"
                >{{ errors.first("Supplier") }}</span
              >
            </div>
          </div>
        </div> -->

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Cost Center</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <multiselect
                class="selectExample"
                v-model="selectedCostCenter"
                :options="optionCostCenter"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelCostCenter"   
                v-validate="'required'" 
                :readonly="true"
              />
              <span class="text-danger text-sm" v-show="errors.has('CostCenter')">{{
                errors.first("CostCenter")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Charge To</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <multiselect
                class="selectExample"
                v-model="selectedCharge"
                :options="optionCharge"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"   
              />
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <!-- <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>COA</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <multiselect
                  class="selectExample"
                  v-model="selectedChartOfAccount"
                  :options="optionChartOfAccount"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  placeholder="Type to search"
                  :searchable="true"
                  :custom-label="customLabelChartOfAccount"   
                  v-validate="'required'" 
                />
              <span class="text-danger text-sm" v-show="errors.has('ChartOfAccount')">{{
                errors.first("ChartOfAccount")
              }}</span>
            </div>
          </div> -->

          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Note</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-textarea v-model="note"  class="w-full" v-validate="'required'" />
              <span class="text-danger text-sm" v-show="errors.has('Note')">{{
                errors.first("Note")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <label
                >Attachment
                <div class="vx-row ml-2 mb-10">
                  <small style="color: red"
                    >(only: xlsx, xls, jpg, jpeg, pdf, png, video)</small
                  >
                </div>
              </label>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <div class="vx-col w-1/2">
                <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                <input
                  id="fileInput"
                  name="file"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".xlsx, .xls, .jpg, .jpeg, .png, .pdf, .mp4, .avi, .mkv, .3gp, .m4v, .wmv, .mov"
                />
              </div>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-button
                class="mr-3 mb-2"
                type="relief"
                icon-pack="feather"
                icon="icon-plus"
                size="small"
                @click="handleAttachment"
                >Attachment</vs-button
              >
              <span class="text-danger text-sm" v-show="errors.has('Attachment')">{{
                  errors.first("Attachment")
                }}</span>
            </div>
          </div>
        </div>


        
        <!-- display: block -->
        <vs-divider style="width: 50%; margin-left: 10%">
          List Attachment
        </vs-divider>
        <div
          class="vx-row mb-3 mt-6 w-4/4"
          style="width: 50%; margin-left: 10%"
        >
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile ? tr.NameFile : tr.FileName }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.Path != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="downloadFileAwsS3(tr.Path)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    
    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      
      <div class="vx-col sm:w-5/5 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-4/12">
            <label class="vs-input--label">Special Area</label>
          </div>
          <div class="vx-col w-5/12">
            <label class="vs-input--label">SKU</label>
          </div>
          <div class="vx-col w-3/12">
            <label class="vs-input--label">Batch / ED</label>
          </div>
        </div>
      </div>

      <div class="vx-row mb-2">
          <div class="vx-col w-4/12">
            <multiselect
            v-model="items[indextr].warehouseArea.selected"
            :options="optionWarehouseArea"        
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelWarehouseArea"
            :required="true"
            @select="getItems($event, indextr)"
            >
            </multiselect>
          </div>

          <div class="vx-col w-5/12">
            <multiselect
            v-model="items[indextr].sku.selected"
            :options="items[indextr].sku.options"        
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelItemSKU"
            @search-change="handleSearchItemSKU"
            @select="getBatch($event, indextr)"
            :required="true"
            >
            </multiselect>
          </div>

          <div class="vx-col w-3/12">
            <multiselect
            v-model="items[indextr].batch.selected"
            :options="items[indextr].batch.options"        
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelBatch"
            :required="true"
            @select="getQtyAvailable($event, indextr)"
            >
            </multiselect>
            <datepicker v-if="isHide" name="expired_date" :inline="false" v-model="items[indextr].expiredDate" placeholder="Select Date"></datepicker>
            
          </div>
      </div>

      <div class="vx-col sm:w-5/5 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Available Qty (UOM)</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">UOM</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">MAP</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Handling Unit</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Qty</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Qty UOM</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Amount</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Reason</label>
          </div>
          <div class="vx-col w-1/12">
            
          </div>
        </div>
      </div>
      <div class="vx-row mb-2">

          <div class="vx-col w-1/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].qtyAvailable"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-1/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].uom"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-2/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].map"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-1/12">
            <multiselect
            v-model="items[indextr].handlingUnit.selected"
            :options="items[indextr].handlingUnit.options"        
            :max-height="125"
            :show-labels="false"
            :allow-empty="false"
            :limit="3"
            placeholder=""
            :searchable="true"
            :required="true"
            @select="getAmountUom($event, indextr)"
            >
            </multiselect>
          </div>

          <div class="vx-col w-1/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].qty"
              @input="getTotalAmount($event, indextr)"
              v-validate="'required'"
              type="number" 
            />
          </div>

          <div class="vx-col w-1/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].qtyUom"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-2/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].amount"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].reason.selected"
            :options="optionReason"        
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelReason"
            >
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('Reason')"
                >{{ errors.first("Reason") }}</span
            >
          </div>

          <div class="vx-col w-1/12" style="display: flex">
              
              <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
                  <vs-button
                      v-if="items[indextr].status=='minus'"
                      style="display: table-cell;"
                      v-on:click="handleRemoveItem(indextr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                  ></vs-button>
                  <vs-button
                      v-else
                      style="display: table-cell;"
                      v-on:click="handleAddItem()"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                  ></vs-button>
              </div>
            </div>
      </div>
      
      <vs-divider></vs-divider>
    </div>

        <div class="vx-col sm:w-5/5 w-full mb-2">
          <div class="vx-row mb-2">
            <div class="vx-col w-4/12">
              <vs-button
                class="mb-2"
                color="success"
                icon-pack="feather"
                type="relief"
                icon="icon-check"
                @click="SubmitForm()"
              >
                Save
              </vs-button>
            </div>            
            <div class="vx-col w-2/12 text-right">
            </div>         
            <div class="vx-col w-1/12">
              <label class="vs-input--label">Total Qty UOM</label>
              <div class="vx-col w-12/12">
                <vs-input
                  class="w-full text-right"
                  v-model="totalQtyUom"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">Total Amount</label>
              <div class="vx-col w-12/12">
                <vs-input
                  class="w-full text-right"
                  v-model="totalAmount"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="vx-col w-1/12">
              
            </div>
            <div class="vx-col w-2/12">
              <vs-button
                class="mb-2"
                icon-pack="feather"
                color="primary"
                type="border"
                icon="icon-x"
                @click="handleClose()"
                >Close</vs-button
              >
            </div>
          </div>
        </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      title: this.$route.query.id ? "Stock Scrap - Edit" : "Stock Scrap - Create",
      editOrShow: false,
      code: null,
      externalCode: null,
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      table: this.tableDefaultState(),
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      posting_date: moment().format("YYYY-MM-DD"),
      file_attachments: 2,
      optionItemSKU: [],
      selectItemSKU: [null],
      optionWarehouseArea: [],
      optionReason: [],
      optionWarehouse: [],
      selectedWarehouse: null,
      optionScrapType: [],
      selectedScrapType: null,
      // optionSupplier: [],
      // selectedSupplier: null,
      optionCostCenter: [],
      selectedCostCenter: null,
      optionChartOfAccount: [],
      selectedChartOfAccount: null,
      optionCharge: ["Internal"], //["Internal", "Supplier"],
      selectedCharge: "Internal",
      note: null,
      itemID: [],
      unitID: [],
      warehouseAreaID: [],
      qtyArray: [],
      qtyAvailableArray: [],
      uomArray: [],
      batchArray: [],
      batchExternalArray: [],
      expiredDateArray: [],
      mapArray: [],
      reasonID: [],
      isHide: false,
      item_unit_id: 0,
      totalAmount:0,
      totalQtyUom:0,
      items: [{
            status: "plus",
            sku:{
                selected: null,
                options: [],
            },
            handlingUnit: {
                selected: null,
                options: [],
            },
            warehouseArea: {
                selected: null,
                options: [],
            },
            reason: {
                selected: null,
                options: [],
            },
            qtyAvailable: null,
            uom: null,
            map: null,
            qty: 0,
            amount: 0,
            amountUom: null,
            qtyUom: null,
            batch: {
                selected: null,
                options: [],
            },
            expiredDate: moment().format("YYYY-MM-DD"),
        }],
      fileAttachment: [],
      disabledDates: {
          from: new Date(Date.now()),
          //to: new Date(Date.now() - 8640000)
      }
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "stock-scrap", query: { tab: this.$route.query.tab } });
      this.$vs.loading.close();
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            by_personal: 1
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getScrapType() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/type", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionScrapType = resp.data.records;
              if (this.optionScrapType.length > 0) {
                this.selectedScrapType = this.optionScrapType[0];
              }
            } 
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/suppliers", {
          params: {
            limit: 1000,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/cost-centers", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            code: "LOGWHS",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCostCenter = resp.data.records;
              this.selectedCostCenter = resp.data.records[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getChartOfAccount() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/chart-of-accounts", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionChartOfAccount = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getReason() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/reason", {
          params: {
            limit: 1000,
            order: "reason",
            sort: "asc",
            reason_group: "Scrap"
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionReason = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItems(val, index) {
        this.items[index].sku.selected = null;
        this.items[index].handlingUnit.selected = null;
        this.items[index].qty = 0;
        this.items[index].batch.selected = null;        
        this.items[index].qtyAvailable = null;
        this.items[index].uom = null;
        this.items[index].map = null;   
        this.items[index].amount = null;   
        this.items[index].amountUom = null;
        this.items[index].qtyUom = null;
        this.$vs.loading();
        this.$http
          .get("/api/wms/v1/wms/item-stock/group-items", {
            params: {
              length: 50,
              query: this.table.search,
              order: "item_name",
              sort: "asc",
              warehouse_id: this.selectedWarehouse.id,
              warehouse_area_id: val.id,
              // supplier_id: this.selectedSupplier.id,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                this.items[index].sku.options = resp.data.records;
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
    },
    getItemsSupplier(val) {
        var _this = this
        this.items.forEach(function(element, index){
          if(_this.items[index].warehouseArea.selected){
            _this.items[index].sku.selected = null;
            _this.items[index].handlingUnit.selected = null;
            _this.items[index].qty = 0;
            _this.items[index].batch.selected = null;
            _this.items[index].qtyAvailable = null;
            _this.items[index].amount = null;
            _this.items[index].uom = null;
            _this.items[index].map = null;
            _this.items[index].reason.selected = null;
            _this.items[index].amountUom = null;
            _this.items[index].qtyUom = null;
            _this.$vs.loading();
            _this.$http
              .get("/api/wms/v1/wms/item-stock/group-items", {
                params: {
                  length: 50,
                  query: _this.table.search,
                  order: "item_name",
                  sort: "asc",
                  warehouse_id: _this.selectedWarehouse.id,
                  warehouse_area_id: _this.items[index].warehouseArea.selected.id,
                  supplier_id: val.id,
                },
              })
              .then((resp) => {
                if (resp.code == 200) {
                  if (resp.data.records) {
                    _this.items[index].sku.options = resp.data.records;
                  }
                  _this.$vs.loading.close();
                } else {
                  _this.$vs.loading.close();
                }
              });
            }
        })
    },
    getItemsEdit(val, warehouse_id, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/group-items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "item_name",
            sort: "asc",
            warehouse_id: warehouse_id,
            warehouse_area_id: val,
            // supplier_id: supplier_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].sku.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getBatch(val, index) {
      this.items[index].handlingUnit.selected = null;
      this.items[index].qty = 0;
      this.items[index].batch.selected = null;
      this.items[index].qtyAvailable = null;
      this.items[index].amount = null;
      this.items[index].uom = null;
      this.items[index].map = null;
      this.items[index].reason.selected = null;
      this.items[index].amountUom = null;
      this.items[index].qtyUom = null;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/group-batch", {
          params: {
            length: 50,
            query: this.table.search,
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: val.sku_code,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].batch.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getQtyAvailable(val, index) {
      this.items[index].handlingUnit.selected = null;
      this.items[index].qty = 0;
      this.items[index].qtyAvailable = null;
      this.items[index].amount = null;
      this.items[index].uom = null;
      this.items[index].map = null;
      this.items[index].amountUom = null;
      this.items[index].qtyUom = null;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/inter-storage/get-available-qty-unit", {
          params: {
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: this.items[index].sku.selected.sku_code,
            batch: val.batch,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.items[index].qtyAvailable = resp.data.available_qty;
              this.items[index].map = this.formatMoney(resp.data.map, 7, ".", ",");
              this.items[index].uom = resp.data.uom;              
              this.items[index].handlingUnit.options = resp.data.option_units;
              this.items[index].amount = this.formatMoney(this.items[index].qty * this.items[index].map, 7, ".", ",");
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAmountUom(val, index){
      this.items[index].amount = null;
      this.items[index].amountUom = null;
      this.items[index].qtyUom = null;
      this.totalAmount = 0;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/item-unit-req", {
          params: {
            sku_code: this.items[index].sku.selected.sku_code,
            unit_name: val,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.items[index].amountUom = resp.data.amount_uom
              this.items[index].qtyUom = this.items[index].qty * resp.data.amount_uom
              this.getTotalAmount(this.items[index].qty, index)
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTotalAmount(val, index){
      this.items[index].amount = this.formatMoney(val * this.items[index].amountUom * this.items[index].map.replace(",",""), 7, ".", ",")
      var _this = this
      var total_amount = 0
      this.totalQtyUom = 0;
      this.items.forEach(function(element, index2){
        total_amount = total_amount + parseFloat(_this.items[index2].amount.replace(",",""))
      })      
      this.items[index].qtyUom = val * this.items[index].amountUom
      this.totalAmount = this.formatMoney(total_amount, 7, ".", ",")
      this.getTotalQtyUom()
    },  
    getTotalQtyUom(){
      console.log("this.totalQtyUom", this.totalQtyUom)
      var _this = this
      var total_qty_uom = 0
      this.items.forEach(function(element, index2){
        total_qty_uom = total_qty_uom + _this.items[index2].qtyUom
      })      
      this.totalQtyUom = total_qty_uom
    },
    getHandlingUnitEdit(warehouse_id, warehouse_area_id, sku_code, batch, qty, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/inter-storage/get-available-qty-unit", {
          params: {            
            warehouse_id: warehouse_id,
            warehouse_area_id: warehouse_area_id,
            sku_code: sku_code,
            batch: batch,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.items[index].qtyAvailable = resp.data.available_qty;
              this.items[index].map = this.formatMoney(resp.data.map, 7, ".", ",");
              this.items[index].uom = resp.data.uom;              
              this.items[index].handlingUnit.options = resp.data.option_units;
              this.items[index].amount = this.formatMoney(qty * resp.data.map, 7, ".", ",");
              this.totalAmount = this.formatMoney(parseFloat(this.totalAmount.toString().replace(",","")) + (qty * resp.data.map), 7, ".", ",")
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getBatchEdit(sku_code, warehouse_area_id, warehouse_id, batch, expired_date, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/group-batch", {
          params: {
            length: 50,
            query: this.table.search,
            warehouse_id: warehouse_id,
            warehouse_area_id: warehouse_area_id,
            sku_code: sku_code,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].batch.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
        
      this.$http
        .get("/api/wms/v1/wms/item-stock/items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "sku_code",
            sort: "asc",
            warehouse_id: warehouse_id,
            warehouse_area_id: warehouse_area_id,
            batch: batch,
            expired_date: expired_date,
            sku_code: sku_code,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].batch.selected = resp.data.records[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWarehouseArea(val) {
      var _this = this
      this.items.forEach(function(element, index){
        _this.items[index].warehouseArea.selected = null
        _this.items[index].sku.selected = null
        _this.items[index].handlingUnit.selected = null  
        _this.items[index].reason.selected = null  
        _this.items[index].qty = 0
        _this.items[index].qtyAvailable = null
        _this.items[index].amount = null
        _this.items[index].uom = null
        _this.items[index].map = null
        _this.items[index].batch.selected = null
        _this.items[index].amountUom = null;
        _this.items[index].qtyUom = null;
      })
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouse-areas/warehouse/"+val.id+"/Special", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouseArea = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWarehouseAreaEdit(val) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouse-areas/warehouse/"+val+"/Special", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouseArea = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.code = resp.data.scrap.Code;
            this.date = resp.data.scrap.Date;
            this.posting_date = resp.data.scrap.PostingDate;
            this.selectedCharge = resp.data.scrap.Charge;
            this.note = resp.data.scrap.Note;
            this.externalCode = resp.data.scrap.ExternalCode;

            if (resp.data.scrap.WarehouseID) {
                this.setWarehouseSelected(resp.data.scrap.WarehouseID);
                this.getWarehouseAreaEdit(resp.data.scrap.WarehouseID);
            }  
            if (resp.data.scrap.ScrapTypeID) {
                  this.setScrapTypeSelected(resp.data.scrap.ScrapTypeID);
            }  
            // if (resp.data.scrap.SupplierID) {
            //       this.setSupplierSelected(resp.data.scrap.SupplierID);
            // }  
            if (resp.data.scrap.CostCenterID) {
                  this.setCostCenterSelected(resp.data.scrap.CostCenterID);
            }  
            if (resp.data.scrap.ChartOfAccountID) {
                  this.setChartOfAccountSelected(resp.data.scrap.ChartOfAccountID);
            }  
            
            this.items = [];
            var statusAdd;
            var tempTotalQtyUom = 0
            for(var k = 0; k < resp.data.scrap_line.length; k++){
              if(k == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }

              _this.items.push({
                  status: statusAdd,
                  sku:{
                      selected: null,
                      options: [],
                  },
                  handlingUnit: {
                      selected: null,
                      options: [],
                  },
                  warehouseArea: {
                      selected: null,
                      options: [],
                  },
                  reason: {
                      selected: null,
                      options: [],
                  },
                  map: resp.data.scrap_line[k].Value,
                  qty: resp.data.scrap_line[k].Quantity,
                  uom: resp.data.scrap_line[k].ItemUom,
                  amount: 0,
                  amountUom: 0,
                  qtyUom:0,
                  batch: {
                      selected: null,
                      options: [],
                  },
                  expiredDate: resp.data.scrap_line[k].ItemExpiredDate,
              })

              if (resp.data.scrap_line[k].WarehouseAreaID) {
                 this.setWarehouseAreaSelected(k, resp.data.scrap_line[k].WarehouseAreaID);
                 this.getItemsEdit(resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, k);
                 this.getHandlingUnitEdit(resp.data.scrap.WarehouseID, resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap_line[k].ItemCode, resp.data.scrap_line[k].ItemBatch, resp.data.scrap_line[k].Quantity, k);
                 this.getBatchEdit(resp.data.scrap_line[k].ItemCode, resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, resp.data.scrap_line[k].ItemBatch, resp.data.scrap_line[k].ItemExpiredDate, k);
              } 

              if (resp.data.scrap_line[k].ItemID) {
                this.setSkuSelected(k, resp.data.scrap_line[k].ItemID);
              } 
              if (resp.data.scrap_line[k].UnitID) {
                this.items[k].handlingUnit.selected = resp.data.scrap_line[k].ItemUnit
              } 
              if (resp.data.scrap_line[k].ReasonID) {
                this.setReasonSelected(k, resp.data.scrap_line[k].ReasonID);
              }  

              tempTotalQtyUom = resp.data.scrap_line[k].QuantityUom + tempTotalQtyUom
              this.fileAttachment = resp.data.scrap_attachment;
            }
            this.totalQtyUom = tempTotalQtyUom
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setWarehouseSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/warehouse/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedWarehouse = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setScrapTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/scrap/type/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedScrapType = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setSupplierSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/supplier/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedSupplier = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCostCenterSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/cost-center/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedCostCenter = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setChartOfAccountSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/chart-of-account/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedChartOfAccount = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setSkuSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/item/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].sku.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setWarehouseAreaSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/warehouse-area/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].warehouseArea.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setReasonSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/reason/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].reason.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    customLabelWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelScrapType({ name, Name }) {
      
      return name ? `${name}` : `${Name}`;
    },
    customLabelSupplier({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelCostCenter({ code, name, Code, Name }) {
      return `${code ? code : Code} - ${name ? name : Name}`;
    },
    customLabelChartOfAccount({ Code, Name }) {
      return `${Code} - ${Name}`;
    },
    customLabelItemSKU({ sku_code, item_name, name }) {
      var skuName = item_name ? item_name : name
      return `${sku_code} - ${skuName}`;
    },
    customLabelWarehouseArea({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelBatch({ batch_external, expired_date }){
      var exDate = this.formatDate(expired_date);
      return `${batch_external} / ${exDate}`;
    },
    customLabelReason({ reason }){
      return `${reason}`;
    },
    handleChangePage(page) {
      this.table.page = page;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
    },
    handleSearchItemSKU(searching) {
      this.table.search = searching;
      this.table.page = 0;
    },
    handleAddItem() {
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          sku:{
            selected: null,
            options: [],
          },
          handlingUnit: {
            selected: null,
            options: [],
          },
          warehouseArea: {
            selected: null,
            options: [],
          },
          reason: {
              selected: null,
              options: [],
          },
          qtyAvailable: null,
          uom: null,
          map: null,
          qty: 0,
          amount: 0,
          amountUom: 0,
          qtyUom: 0,
          batch:{
              selected: null,
              options: [],
          },
          expiredDate: moment().format("YYYY-MM-DD"),
      })
    },
    handleRemoveItem(index) {
      this.items.splice(index, 1)
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    SubmitForm() {

      this.$validator.validateAll().then((result) => {
        // if (this.selectedSupplier == null) {
        //   this.errors.add({
        //     field: "Supplier",
        //     msg: "This field is required",
        //   });
        //   result = false;
        // }
        if (this.selectedCostCenter == null) {
          this.errors.add({
            field: "CostCenter",
            msg: "This field is required",
          });
          result = false;
        }
        // if (this.selectedChartOfAccount == null) {
        //   this.errors.add({
        //     field: "ChartOfAccount",
        //     msg: "This field is required",
        //   });
        //   result = false;
        // }
        if (this.selectedWarehouse == null) {
          this.errors.add({
            field: "Warehouse",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedScrapType == null) {
          this.errors.add({
            field: "ScrapType",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.note == null) {
          this.errors.add({
            field: "Note",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.externalCode == null) {
          this.errors.add({
            field: "ExternalCode",
            msg: "This field is required",
          });
          result = false;
        }
        
        
        var _this = this
        this.items.forEach(function(element, index){
          if (_this.items[index].sku.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'SKU is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].handlingUnit.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Handling Unit is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].warehouseArea.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Special Area is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else  if (_this.items[index].qty == 0) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Qty is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else  if (_this.items[index].batch.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Batch is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].reason.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Reason is required!',
                  acceptText: 'OK',
              });
              result = false;
          }
        })

        if (this.fileAttachment.length <= 0){
          this.errors.add({
            field: "Attachment",
            msg: "Attachment is required",
          });
          result = false;
        }
        
        if (result) {
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }         
        }
      });


    },
    postData() {
      let form = new FormData();
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", moment(this.date).format("YYYY-MM-DD"));
      form.append("posting_date", moment(this.posting_date).format("YYYY-MM-DD"));
      form.append("scrap_type_id", this.selectedScrapType.id);
      // form.append("supplier_id", this.selectedSupplier.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("charge", this.selectedCharge);
      // form.append("chart_of_account_id", this.selectedChartOfAccount.id);
      form.append("note", this.note);
      form.append("external_code", this.externalCode);

      var _this = this
      if(this.items[0].sku.selected){
        this.items.forEach(function(element, index){
            _this.itemID[index] = _this.items[index].sku.selected.sku_code ? _this.items[index].sku.selected.sku_code : null
            _this.unitID[index] = _this.items[index].handlingUnit.selected
            _this.warehouseAreaID[index] = _this.items[index].warehouseArea.selected.ID ? _this.items[index].warehouseArea.selected.ID : _this.items[index].warehouseArea.selected.id  
            _this.reasonID[index] = _this.items[index].reason.selected.ID ? _this.items[index].reason.selected.ID : _this.items[index].reason.selected.id   
            _this.qtyArray[index] =  _this.items[index].qty
            _this.qtyAvailableArray[index] =  _this.items[index].qtyAvailable
            _this.uomArray[index] =  _this.items[index].uom
            _this.batchArray[index] =  _this.items[index].batch.selected.batch
            _this.batchExternalArray[index] =  _this.items[index].batch.selected.batch_external
            _this.expiredDateArray[index] =  moment(_this.items[index].batch.selected.expired_date).format("YYYY-MM-DD")
            _this.mapArray[index] =  parseFloat(_this.items[index].map.toString().replace(",",""))
            
            form.append("item_code[]", _this.itemID[index]);
            form.append("unit[]", _this.unitID[index]);
            form.append("item_unit_id[]", _this.items[index].batch.selected.item_unit_id);
            form.append("warehouse_area_id[]", _this.warehouseAreaID[index]);
            form.append("qty[]", _this.qtyArray[index]);
            form.append("qty_available[]", _this.qtyAvailableArray[index]);
            form.append("uom[]", _this.uomArray[index]);
            form.append("map[]", _this.mapArray[index]);
            form.append("batch[]", _this.batchArray[index]);
            form.append("batch_external[]", _this.batchExternalArray[index]);
            form.append("expired_date[]", _this.expiredDateArray[index]);
            form.append("reason_id[]", _this.reasonID[index]);
        })
      }

      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/scrap/create", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Scrap has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    putData() {
      
      let form = new FormData();
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", moment(this.date).format("YYYY-MM-DD"));
      form.append("posting_date", moment(this.posting_date).format("YYYY-MM-DD"));
      form.append("scrap_type_id", this.selectedScrapType.id);
      // form.append("supplier_id", this.selectedSupplier.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("charge", this.selectedCharge);
      // form.append("chart_of_account_id", this.selectedChartOfAccount.id);
      form.append("note", this.note);
      form.append("external_code", this.externalCode);

      var _this = this
      if(this.items[0].sku.selected){
        this.items.forEach(function(element, index){
            _this.itemID[index] = _this.items[index].sku.selected.sku_code ? _this.items[index].sku.selected.sku_code : null 
            _this.unitID[index] = _this.items[index].handlingUnit.selected
            _this.warehouseAreaID[index] = _this.items[index].warehouseArea.selected.ID ? _this.items[index].warehouseArea.selected.ID : _this.items[index].warehouseArea.selected.id  
            _this.reasonID[index] = _this.items[index].reason.selected.ID ? _this.items[index].reason.selected.ID : _this.items[index].reason.selected.id   
            _this.qtyArray[index] =  _this.items[index].qty
            _this.qtyAvailableArray[index] =  _this.items[index].qtyAvailable
            _this.uomArray[index] =  _this.items[index].uom
            _this.batchArray[index] =  _this.items[index].batch.selected.batch
            _this.batchExternalArray[index] =  _this.items[index].batch.selected.batch_external
            _this.expiredDateArray[index] = moment(_this.items[index].batch.selected.expired_date).format("YYYY-MM-DD")
            _this.mapArray[index] =  parseFloat(_this.items[index].map.toString().replace(",",""))

            form.append("item_code[]", _this.itemID[index]);
            form.append("unit[]", _this.unitID[index]);
            form.append("item_unit_id[]", _this.items[index].batch.selected.item_unit_id);
            form.append("warehouse_area_id[]", _this.warehouseAreaID[index]);
            form.append("qty[]", _this.qtyArray[index]);
            form.append("qty_available[]", _this.qtyAvailableArray[index]);
            form.append("uom[]", _this.uomArray[index]);
            form.append("map[]", _this.mapArray[index]);
            form.append("batch[]", _this.batchArray[index]);
            form.append("batch_external[]", _this.batchExternalArray[index]);
            form.append("expired_date[]", _this.expiredDateArray[index]);
            form.append("reason_id[]", _this.reasonID[index]);
        })
      }

      var attachment_id
      for (let index = 0; index < this.fileAttachment.length; index++) {
        if(typeof this.fileAttachment[index].id === "undefined"){
          attachment_id = 0
        }else{
          attachment_id = this.fileAttachment[index].id
        }
        form.append("attachment_id[]", attachment_id);
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/scrap/update/" + this.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Scrap has been updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    formatMoney(val, decimal, decSep, thouSep) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(decimal).replace(thouSep, decSep);
      var valsplit = val.split(".")
      return valsplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"."+valsplit[1];
    },
  },

  mounted() {
    this.getWarehouse();
    this.getScrapType();
    // this.getSupplier();
    this.getCostCenter();
    // this.getChartOfAccount();
    this.getReason();
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    // "selectedSupplier": function (val) {
    //   if (val) {
    //     this.errors.clear();
    //     this.selectedSupplier = val;
    //   } else {
    //     this.selectedSupplier = null;
    //   }
    // },
    "selectedCostCenter": function (val) {
      if (val) {
        this.errors.clear();
        this.selectedCostCenter = val;
      } else {
        this.selectedCostCenter = null;
      }
    },
    // "selectedChartOfAccount": function (val) {
    //   if (val) {
    //     this.errors.clear();
    //     this.selectedChartOfAccount = val;
    //   } else {
    //     this.selectedChartOfAccount = null;
    //   }
    // },
  },
  computed: {
    formatDate: () => {
        return (val) => {
            const momentDate = moment.utc(val);
            return momentDate.format('DD MMM YYYY');
        };
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
